import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import MenuHeader from './MenuHeader';
import Card from './Card';

const Bar = styled.div`
  width: 0;
  flex-grow: 1;
  &:nth-child(even) {
    background-color: #92C3EA;
  }
  &:nth-child(odd) {
    background-color: #BDDEFF;
  }
`;

const TimelineWrapper = styled.div`
  height: 6em;
  color: #fff;
  position: relative;
  font-size: .8em;
`;

const TimelineContent = styled.div`
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  width: 100%;
  height: 100%;
`;

const Label = styled.div`
  position: absolute;
  font-size: 0.4em;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  font-weight: bold;
  top: 0em;
  width: 100%;
  text-align: center;
`;



const ranges = [14, 60, 120];

export default class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rangeIndex: 0,
    }
  }
  changeRange = () => {
    const rangeIndex = (this.state.rangeIndex + 1) % ranges.length;
    this.setState({ rangeIndex });
  }

  render() {
    const { history } = this.props;
    if (!history.length) {
      return 'suck it'
    }
    const { rangeIndex } = this.state;
    const daysPast = ranges[rangeIndex];
    const minDate= new Date();
    minDate.setDate(minDate.getDate() - daysPast);
    const snacksInRange = _.filter(history, snack => new Date(snack.date) > minDate);
    const days = {};
    const grouping = {};
    _.each(snacksInRange, snackRecord => {
      const date = new Date(snackRecord.date);
      const key = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      days[key] = days[key] || 0;
      grouping[key] = grouping[key] || {};
      days[key] += 1;
      grouping[key][snackRecord.snackType] = grouping[key][snackRecord.snackType] ?
        grouping[key][snackRecord.snackType] + 1 : 1;
    })
    console.log('GR', grouping);
    const data = _.toPairs(days).map(day => ({ date: day[0], times: day[1] }));
    const biggestDay = _.maxBy(data, d => d.times);
    return (
      <Card onClick={this.changeRange}>
        <MenuHeader>Snax Timeline</MenuHeader>
        <TimelineWrapper>
          <TimelineContent>
          {
            data.map(d => <Bar style={{height: `${60 * d.times / biggestDay.times}%` }} />)
          }
          </TimelineContent>
        <Label>{daysPast} days | AVG 20</Label>
      </TimelineWrapper>
    </Card>

    );
  }
}
