import React from 'react';
import ToolIcon from './ToolIcon';

export default function Juggling({ isSelected, onClick }) {
  return (
    <ToolIcon onClick={onClick} isSelected={isSelected} color="#A8DD17" name="wall">
      <path className="shadow" d="M23.3,16l-12-12v15.7h9.8C22.1,18.6,22.8,17.3,23.3,16z"/>
      <path className="content" d="M11.4,19.7V4H9.6v15.7H2.8C5,22.3,8.3,24,12,24s7-1.7,9.2-4.3H11.4z"/>
    </ToolIcon>
  );
}
