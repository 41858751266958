import Stick from './Stick';
import Jenga from './Jenga';
import PullupBar from './PullupBar';
import Juggling from './Juggling';
import Wall from './Wall';

import { STICK, JENGA, PULLUP, JUGGLING, WALL } from '../models/tools';

export default [
  {
    name: STICK,
    icon: Stick,
  },
  {
    name: JENGA,
    icon: Jenga,
  },
  {
    name: PULLUP,
    icon: PullupBar,
  },
  {
    name: JUGGLING,
    icon: Juggling,
  },
  {
    name: WALL,
    icon: Wall,
  }
];
