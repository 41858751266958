export const GRIT = 'grit';
export const COORDINATION = 'coordination';
export const POWER = 'power';
export const STRENGTH = 'strength';
export const MOBILITY = 'mobility';

export default {
  [GRIT]: {
    name: 'Grit',
    color: '#B337D1',
  },
  [COORDINATION]: {
    name: 'Coordination',
    color: '#347BF8',
  },
  [POWER]: {
    name: 'Power',
    color: '#E62A60',
  },
  [STRENGTH]: {
    name: 'Strength',
    color: '#FF6D00',
  },
  [MOBILITY]: {
    name: 'Mobility',
    color: '#94CE00',
  },
}


