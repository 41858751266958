import React, { Component } from 'react';
import ToolIcon from './ToolIcon';

export default function Stick({ isSelected, onClick }) {
  return (
    <ToolIcon onClick={onClick} isSelected={isSelected} color="#935836" name="stick">
      <path className="shadow" d="M24,12c0-0.6-0.1-1.1-0.1-1.7L18.5,5L6.3,19l5,5c0.2,0,0.5,0,0.7,0C18.6,24,24,18.6,24,12z"/>
      <polygon className="content" points="5,17.1 8.6,14.5 11.1,12 12.8,9.6 13.4,6.3 14.7,6.8 14.5,8.9 18.5,5 19,6.3 15.9,10.3 13.3,12.3
	12.9,12.8 13.7,12.8 14.2,13.4 10.9,15 10.7,15.9 6.3,19 "/>
    </ToolIcon>
  );
}
