import React from 'react';
import ToolIcon from './ToolIcon';

export default function Juggling({ isSelected, onClick }) {
  return (
    <ToolIcon onClick={onClick} isSelected={isSelected} color="#A925B7" name="juggling balls">
      <path className="shadow" d="M4.3,13.7l9.9,10c2.8-0.5,5.2-2,6.9-4.1l-4.5-4.5l-1.1,2.4l-7.5-7.5"/>
      <path className="shadow" d="M13.1,8.9l9.2,9.2c1-1.7,1.6-3.6,1.7-5.7l-6.8-6.8"/>
      <circle className="content" cx="6.2" cy="11.9" r="2.6"/>
      <circle className="content" cx="14.8" cy="17" r="2.6"/>
      <circle className="content" cx="14.9" cy="7" r="2.6"/>
    </ToolIcon>
  );
}
