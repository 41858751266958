import React, { Component } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.button`
  .shadow {
    fill: none;
  }
  .content {
    fill: rgba(255, 255, 255, 0.6);
  }
  padding: 0;
  border: 0;
  background-color: rgba(0, 0, 0, .3);
  border-radius: 50%;
  width: ${props => props.size || "48"}px;
  height: ${props => props.size || "48"}px;

  ${props => props.isSelected && css`
    background-color: ${props.color};
    .shadow {
      fill: rgba(0,0,0,.2);
    }
    .content {
      fill: #fff;
    }
  `}
  flex-shrink: 0;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export default function ToolIcon({ onClick, name, children, isSelected, color, size = 64 }) {
  return (
    <Wrapper onClick={() => onClick(name)} isSelected={isSelected} color={color}>
      <svg viewBox="0 0 24 24">
        {children}
      </svg>
    </Wrapper>
  );
}
