import React, { Component } from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';

import './App.css';
import snax from './models/snacks';
import Video from './Video';
import ToolMenu from './ToolMenu';
import Timeline from './Timeline';
import CounterTop from './CounterTop';
import toys from './toys';
import Slider from './Slider';
import api from './api';

const Logo = styled.img`
  height: 2em;
  position: absolute;
  left: 1.5em;
  top: 1.5em;
  transition: filter .3s;
  ${props => props.showingMenu && css`
    filter: brightness(0%);
  `}
`;

const Container = styled.div`


  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
`;

class App extends Component {
  constructor() {
    super();
    this.state = {
      snackIndex: 0,
      showingMenu: false,
      availableToys: [],
      history: [],
      isPlaying: false,
    };
    api.loadData.then(history => this.setState({ history }));
    api.getAvailableTools.then(availableToys => this.setState({ availableToys }));
  }

  toggleMenu = () => {
    this.setState({ showingMenu: !this.state.showingMenu });
  }

  toggleToy = toy => {
    const { availableToys } = this.state;
    const newList = _.indexOf(availableToys, toy) === -1 ?
      [...availableToys, toy] : _.without(availableToys, toy);
    this.setState({ availableToys: newList });
    api.saveToolList(newList);
  }

  advance = () => {
    const newIndex = (this.state.snackIndex + 1) % snax.length;
    this.setState({ snackIndex: newIndex });
  }

  render() {
    console.log('this.state.', this.state.history);
    const { snackIndex, showingMenu, availableToys, history, isPlaying } = this.state;
    const snack = snax[snackIndex];
    const toysWithAvailibility = toys.map(t => ({
      ...t,
      isAvailable: _.indexOf(availableToys, t.name) > -1,
    }))
    if (isPlaying) {
      return <div>oh snap</div>;
    }

    return (
      <Container style={{backgroundImage: 'url("/snax/background.png")'}}>
      <div style={{ backgroundColor: 'rgba(0,0,0,.8)', color: '#fff'}}>
        <div style={{textAlign:'center', padding: '1em'}}><h1>MOVESNAX</h1>
        <div style={{marginTop: '1em'}}>
          Granola bars are well packaged. <br/> Movement snacks are not. <br/>We're going to fix that.
        </div>
        <div style={{marginTop: 20, marginBottom: 40, color: '#fff'}}><a style={{color: '#fff'}}  href="https://docs.google.com/forms/d/e/1FAIpQLSc4rj52aGa79ChTSV-ospRQrfqLTXaWr33SmVfIQQEZcZVntQ/viewform?usp=sf_link">Let me know when you <br/> launch something</a></div>
        </div>
      </div>
      </Container>
    );
  }
}

export default App;
