import history from './utils/generateHistory';

export default {
  loadData: new Promise((resolve, reject) => {
    resolve(history);
    //resolve(JSON.parse(history).history);
    //const savedData = window.localStorage.getItem('movesnax') || history;
    //resolve(JSON.parse(savedData).history);
  }),

  getAvailableTools: new Promise((resolve, reject) => {
    const savedData = window.localStorage.getItem('availableTools') || '{"tools": []}';
    resolve(JSON.parse(savedData).tools);
  }),

  saveToolList: tools => {
    window.localStorage.setItem('availableTools', JSON.stringify({ tools }));
  }
}
