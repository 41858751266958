import _ from 'lodash';
import snax from '../models/snacks';

const history = [];
const currentD = new Date();
currentD.setTime(currentD.getTime() - 1000*60*60*24*143);
for (let i = 0; i <= 2000; i++) {

  const numToAdd = Math.floor(currentD.getMonth() * 2 + Math.random() * 4 * Math.abs(Math.sin(currentD.getDate() / 30)));
  for (let j = 0; j < numToAdd; j++) {
    currentD.setTime(currentD.getTime() + 10000);
    const thisSnack = _.sample(snax);
    history.push({
      date: currentD.toJSON(),
      snackId: thisSnack.id,
      value: Math.random() * 100  + 4,
      snackType: thisSnack.snackType,
    });
  }
  currentD.setTime(currentD.getTime() + 1000*60*60 + Math.random() * 5000000 );
}

export default history;
