import React from 'react';
import styled, { css } from 'styled-components';

const WIDTH = 240;
const BG_COLOR = '#fff';

const Arrow = styled.polyline`
  stroke-linecap:round;
  stroke-linejoin:round;
  stroke: #fff;
  stroke-width: 4;
  transform-origin: 50% 50%;
  transition: transform .3s;
  fill: none;
`;

const MenuButton = styled.svg`
  left: ${WIDTH}px;
  width: 32px;
  height: 160px;
  position: absolute;
  top: 50%;
  margin-top: -80px;
  path {
    fill: ${BG_COLOR};
  }
`;

const SliderWrapper = styled.div`
  transition: transform .3s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  left: -${WIDTH}px;
  position: absolute;
  top: 0;
  height: 100%;

  ${props => props.isOpen && css`
    transform: translate(${WIDTH}px);
    box-shadow: 4px 0 5px 0 rgba(0,0,0,.2);
  `}

`;

const ContentWrapper = styled.div`
  width: ${WIDTH}px;
  background-color: ${BG_COLOR};
  height: 100%;
  overflow-y: auto;

`;

export default function Slider({ isOpen, onToggle, children }) {
  const arrowStyle = {}; // isOpen ? { transform: `translate(-2px) rotate(180deg) scale(0.6)` } : {};
  return (
    <SliderWrapper isOpen={isOpen}>
      <ContentWrapper>
        {children}
      </ContentWrapper>

    </SliderWrapper>
  );
}

/*<MenuButton onClick={onToggle} viewBox="0 0 32 160">
<path d="M0,0c0,30.3,32,54.6,32,80S0,129.7,0,160L0,0z"/>
<Arrow style={arrowStyle} points="8.8,93.4 21.2,79.5 8.8,65.5 "/>
</MenuButton>*/
