import React from 'react';
import styled from 'styled-components';

import MenuHeader from './MenuHeader';
import Card from './Card';

const ScrollBox = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
  > * {
    margin: 0.65em;
    font-size: 0.5em;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
  }
`;

export default function ToolMenu({ tools, onClick }) {
  return (
    <Card>
      <MenuHeader>Available Equipment</MenuHeader>
      <ScrollBox>
        {
          tools.map(toy => (
            <div>
              <toy.icon key={toy.name} onClick={onClick} isSelected={toy.isAvailable} />
              <div>{toy.name}</div>
            </div>
          ))
        }
      </ScrollBox>
   </Card>
  );
}
