import React, { Component } from 'react';
import styled, { css } from 'styled-components';

const VideoWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  transition: filter .3s;
`;

const Action = styled.div`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 2px solid #fff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,.3);
  font-size: 1.25em;
`;

export default class Video extends Component {
  setRef = ref => this.video = ref

  componentDidUpdate(_prevProps, _prevState) {
    if (_prevProps.url !== this.props.url) {
      this.video.load();
    }
     // you can add logic to check if sources have been changed
  }

  render() {
    const { url, title, img, isCovered, onPlay, onPass } = this.props;
    return (
      <VideoWrapper isCovered={isCovered}>
        {
          url && (<video ref={this.setRef} height="100%" width="100%">
            <source src={url} />
          </video>)
        }
        {
          img && <img src={img} style={{width: '100%', height: '100%'}} />
        }
        {
          !img && !url && <img src="/snax/default.png" style={{width: '100%', height: '100%'}} />
        }

        <div style={{width: '100%', height: '100%', position: 'absolute', left: 0, top: 0, backgroundColor: 'rgba(0,0,0,.5)'}} />
        <div style={{position: 'absolute', top: 100, left: 30, fontSize: '2em', color: '#fff',}}>{title}</div>
        <div style={{width: '100%', display: 'flex', position: 'absolute', bottom: 0, height: '50%', justifyContent: 'space-around', alignItems: 'center'}}>
          <Action onClick={onPass}>Meh</Action>
          <Action onClick={onPlay}>Yay</Action>
        </div>

      </VideoWrapper>
    );
  }
}

