import React, { Component } from 'react';
import ToolIcon from './ToolIcon';

export default function Jenga({ isSelected, onClick }) {
  return (
    <ToolIcon onClick={onClick} isSelected={isSelected} color="#BA7625" name="jenga blocks">
      <path className="shadow" d="M23.1,14.4l-3.6-3.6l-6.4-6.4l-8.7,8.7l6.4,6.4l3.6,3.6l0.5,0.5c4.3-1.1,7.6-4.4,8.7-8.7L23.1,14.4z"/>
      <rect x="7.3" y="6.2" transform="matrix(0.7071 0.7071 -0.7071 0.7071 10.1153 -2.8467)" className="content" width="2.4" height="9.1"/>
	    <rect x="9.6" y="8.6" transform="matrix(0.7071 0.7071 -0.7071 0.7071 12.4749 -3.8241)" className="content" width="2.4" height="9.1"/>
	    <rect x="12" y="11" transform="matrix(0.7071 0.7071 -0.7071 0.7071 14.8344 -4.8014)" className="content" width="2.4" height="9.1"/>
	    <rect x="14.3" y="4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -1.4645 13.4523)" className="content" width="2.4" height="9.1"/>
    </ToolIcon>
  );
}
