import React, { Component } from 'react';
import ToolIcon from './ToolIcon';

export default function Stick({ isSelected, onClick }) {
  return (
    <ToolIcon onClick={onClick} isSelected={isSelected} color="#4AC8F4" name="pullup bar">
      <path className="shadow" d="M12,24c3.2,0,6.1-1.3,8.3-3.3L5.1,5.4v4v12.3C7,23.2,9.4,24,12,24z"/>
      <path className="shadow" d="M24,12c0-1-0.1-2-0.4-3L20,5.4v5.3v10.1C22.5,18.7,24,15.5,24,12z"/>
      <rect x="4.5" y="6.8" className="content" width="15" height="1"/>
      <path className="content" d="M3.5,20.5c0.6,0.6,1.3,1.1,2,1.6V5.4h-2V20.5z"/>
      <path className="content" d="M18.5,22.1c0.7-0.5,1.4-1,2-1.6V5.4h-2V22.1z"/>
    </ToolIcon>
  );
}
