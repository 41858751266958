import tools from './tools';

import { COORDINATION, MOBILITY, STRENGTH, POWER, GRIT } from './snack-types';
import { MAX_REPS, MAX_DURATION, MAX_WEIGHT } from './measurement-types';
import { STICK } from './tools';

const STICK_BALANCING = 'stick balancing';
const HANDSTANDS = 'handstands';
const BENT_ARM_PUSH = 'bent arm push';
const HIP_HINGING = 'hip hinging';
const JUGGLING = 'juggling';
const HIP_ABDUCTION = 'hip abduction';

export default [
  {
    name: 'sticky passy',
    img: '/snax/stick.png',
    type: MAX_REPS,
    tools: [STICK],
    id: 1,
    snackType: COORDINATION,
    level: 2,
    snackGroup: STICK_BALANCING,
  },
  {
    name: 'handstand hold',
    img: '/snax/handstand.png',
    type: MAX_DURATION,
    id: 2,
    snackType: STRENGTH,
    level: 2,
    snackGroup: HANDSTANDS,
  },
  {
    name: 'handstand pushups',
    type: MAX_REPS,
    img: '/snax/hspu.png',
    id: 3,
    snackType: STRENGTH,
    level: 3,
    snackGroup: BENT_ARM_PUSH,
  },
  {
    name: 'tose pose & fold',
    type: MAX_REPS,
    img: '/snax/fold.png',
    id: 4,
    snackType: MOBILITY,
    level: 2,
    snackGroup: HIP_HINGING,
  },
  {
    name: 'open & close straddles',
    type: MAX_REPS,
    img: '/snax/straddle_handstand.png',
    id: 5,
    snackType: COORDINATION,
    level: 3,
    snackGroup: HANDSTANDS,
  },
  {
    name: 'throws & catches with 3 balls',
    type: MAX_REPS,
    id: 6,
    snackType: COORDINATION,
    level: 1,
    snackGroup: JUGGLING,
  },
  {
    name: 'horse pose',
    type: MAX_DURATION,
    id: 7,
    img: '/snax/horse.png',
    snackType: GRIT,
    level: 1,
    snackGroup: HIP_ABDUCTION,
  },
  {
    name: 'weighted muscle up',
    type: MAX_WEIGHT,
    id: 8,
    snackType: POWER,
    level: 3,
    snackGroup: [BENT_ARM_PUSH],
  },
];
